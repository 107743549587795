import { gql } from "@apollo/client";
import { Classes, Intent } from "@blueprintjs/core";
import {
  DOCS_LINKS,
  HexId,
  HexVersionId,
  ORG_ROLE_ORDERING,
  OrgRole,
  UserId,
} from "@hex/common";
import { darken } from "polished";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { client } from "../../client.js";
import { HexButton } from "../../hex-components/HexButton.js";
import { HexDialog } from "../../hex-components/HexDialog.js";
import { Txt } from "../../hex-components/Txt.js";
import { ORG_ID } from "../../orgs.js";
import { useUserToTranferToQuery } from "../admin/DeactivateUserDialog.generated.js";
import { DocsLink } from "../common/DocsLink.js";
import { useToaster } from "../common/Toasts.js";
import {
  UserGroupSelectSearchDocument,
  UserGroupSelectSearchQuery,
  UserGroupSelectSearchQueryVariables,
} from "../common/UserGroupSelect.generated.js";
import {
  UserGroupSearchResponse,
  UserGroupSelect,
  UserGroupSelectStub,
} from "../common/UserGroupSelect.js";
import { CrossIcon } from "../icons/CustomIcons.js";
import { UserAvatar } from "../user/UserAvatar.js";

import { useSetProjectOwnerMutation } from "./TransferProjectOwnerDialog.generated.js";
import { GetHexForShareDialogDocument } from "../share/ShareDialogPopoverContentNew.generated.js";

interface CurrentOwnerUserInfo {
  id: UserId;
  nameOrEmail: string;
}

interface TransferProjectOwnerDialogProps {
  currentOwner: CurrentOwnerUserInfo | null;
  hexId: HexId;
  isComponent: boolean;
  closeDialog: () => void;
  isOpen?: boolean;
  // If set, we will refetch the share dialog info.
  // Note that this is only necessary to do within the project view.
  refetchShareDialogInfo?: {
    hexVersionId: HexVersionId;
  };
}

gql`
  mutation SetProjectOwner($hexId: HexId!, $nextOwnerUserId: UserId!) {
    setProjectOwner(hexId: $hexId, nextOwnerUserId: $nextOwnerUserId) {
      id
      ...SubNavigationBarActionsHexFragment
    }
  }
`;

export const TransferProjectOwnerDialog: React.ComponentType<TransferProjectOwnerDialogProps> =
  React.memo(function TransferProjectOwnerDialog({
    closeDialog,
    currentOwner,
    hexId,
    isComponent,
    isOpen = true,
    refetchShareDialogInfo,
  }) {
    const toaster = useToaster();
    const [nextOwnerUserId, setNextOwnerUserId] = useState<UserId | null>();
    const [setProjectOwnerMutation] = useSetProjectOwnerMutation({
      // Only refetch the share dialog data if this dialog is rendered from the project
      // context. This is so that the client can immediately see that the project
      // grants in the share dialog have been updated. If this action is taken from
      // the homepage or via bulk action, we do not want to refetch this data.
      refetchQueries:
        refetchShareDialogInfo != null
          ? [
              {
                query: GetHexForShareDialogDocument,
                variables: {
                  hexId,
                  hexVersionId: refetchShareDialogInfo.hexVersionId,
                  getSharedComponentCount: isComponent,
                },
              },
            ]
          : [],
    });

    const selectedUserIds = useMemo(
      () => (nextOwnerUserId != null ? [nextOwnerUserId] : []),
      [nextOwnerUserId],
    );
    const setSelectedUserIds = useCallback(
      (setStateCallback: (prevUsers: readonly UserId[]) => UserId[]) => {
        const newUserId = setStateCallback([])[0];
        setNextOwnerUserId(newUserId ?? undefined);
      },
      [setNextOwnerUserId],
    );

    const closeDialogCallback = useCallback(() => {
      setNextOwnerUserId(null);
      closeDialog();
    }, [closeDialog]);

    const setProjectOwnerCallback = useCallback(async () => {
      if (nextOwnerUserId == null) {
        return;
      }
      try {
        await setProjectOwnerMutation({
          variables: { hexId, nextOwnerUserId },
        });
        toaster.show({
          message: "Project ownership successfully transferred.",
          intent: Intent.SUCCESS,
        });
        closeDialogCallback();
      } catch (e) {
        toaster.show({
          message: "Error transferring project owner",
          intent: Intent.DANGER,
        });
        console.error(e);
      }
    }, [
      hexId,
      nextOwnerUserId,
      setProjectOwnerMutation,
      toaster,
      closeDialogCallback,
    ]);

    const { data: transferProjectOwnershipToUserData } =
      useUserToTranferToQuery({
        skip: nextOwnerUserId == null,
        variables: {
          userId: nextOwnerUserId!,
        },
      });

    const searchUsersCallback = useCallback(
      async (
        userPageSize: number,
        groupPageSize: number,
        searchString: string,
      ): Promise<UserGroupSearchResponse> => {
        const {
          data: searchData,
          error,
          loading,
        } = await client.query<
          UserGroupSelectSearchQuery,
          UserGroupSelectSearchQueryVariables
        >({
          query: UserGroupSelectSearchDocument,
          variables: {
            searchString,
            orgId: ORG_ID,
            searchUsers: true,
            searchGroups: false,
            searchIntrinsicGroups: false,
            userPageSize,
            groupPageSize,
            // Only allow the next project owner to be at least an Org Editor (guests / members excluded)
            orgRoleFilter: ORG_ROLE_ORDERING.filter(
              (orgRole): boolean =>
                ({
                  [OrgRole.ADMIN]: true,
                  [OrgRole.MANAGER]: true,
                  [OrgRole.EDITOR]: true,
                  [OrgRole.EXPLORER]: false,
                  [OrgRole.MEMBER]: false,
                  [OrgRole.GUEST]: false,
                  [OrgRole.ANONYMOUS]: false,
                })[orgRole],
            ),
          },
        });
        const result: UserGroupSelectStub[] =
          searchData?.searchOrgUsers?.slice() ?? [];
        return {
          data: result,
          error,
          loading,
        };
      },
      [],
    );

    const hexType = isComponent ? "component" : "project";

    return (
      <HexDialog
        isCloseButtonShown={true}
        isOpen={isOpen}
        title={`Transfer ${hexType} owner`}
        usePortal={true}
        onClose={closeDialogCallback}
      >
        <div
          className={Classes.DIALOG_BODY}
          css={`
            display: flex;
            flex-direction: column;
            gap: 10px;
          `}
        >
          {currentOwner != null ? (
            <span>
              <Txt fontWeight="semi_bold">{currentOwner.nameOrEmail} </Txt>
              is the current owner of this <span>{hexType}</span>. Transferring
              ownership will grant the new owner{" "}
              <Txt fontWeight="semi_bold">Full Access</Txt> to the{" "}
              <span>{hexType}</span>
              .
              <br />
              <br />
              Transferring ownership does not change the current owner&apos;s
              access <DocsLink to={DOCS_LINKS.Transfers}>Learn more.</DocsLink>
            </span>
          ) : (
            <span>
              There is no current owner on this project. Please select a new
              owner.
            </span>
          )}
          <div>
            {nextOwnerUserId != null ? (
              <UserInfo>
                <UserAvatar
                  email={transferProjectOwnershipToUserData?.userById.email}
                  imageUrl={
                    transferProjectOwnershipToUserData?.userById.imageUrl ??
                    undefined
                  }
                  name={
                    transferProjectOwnershipToUserData?.userById.name ??
                    undefined
                  }
                  role={
                    transferProjectOwnershipToUserData?.userById.orgRole ??
                    undefined
                  }
                  size={20}
                />
                <UserTitle ellipsize={true}>
                  {transferProjectOwnershipToUserData?.userById.name ??
                    transferProjectOwnershipToUserData?.userById.email}
                </UserTitle>
                <RemoveUser
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => setNextOwnerUserId(undefined)}
                >
                  <CrossIcon
                    css={`
                      display: initial;
                    `}
                    iconSize={16}
                  />
                </RemoveUser>
              </UserInfo>
            ) : (
              <UserGroupSelect
                placeholder="Select new owner..."
                selectedUserIds={selectedUserIds}
                setSelectedUserIds={setSelectedUserIds}
                onSearchCallback={searchUsersCallback}
              />
            )}
          </div>
        </div>
        <div
          className={Classes.DIALOG_FOOTER}
          css={`
            display: flex;
            justify-content: flex-end;
            gap: 5px;
          `}
        >
          <HexButton onClick={closeDialogCallback}>Cancel</HexButton>
          <HexButton
            disabled={nextOwnerUserId == null}
            intent={Intent.SUCCESS}
            onClick={setProjectOwnerCallback}
          >
            Update
          </HexButton>
        </div>
      </HexDialog>
    );
  });

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  padding: 4px;
  gap: 4px;

  background-color: ${({ theme }) => darken(0.05, theme.backgroundColor.MUTED)};
  border-radius: ${({ theme }) => theme.borderRadius};

  box-shadow: ${({ theme }) => theme.boxShadow.CARD};
`;

const RemoveUser = styled.span`
  margin-left: 4px;

  cursor: pointer;
`;

const UserTitle = styled(Txt)`
  margin-left: 4px;
`;
